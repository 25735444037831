@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.groupInfo {
	@include flex(row, space-evenly, center);

	width: 100%;
	height: 100%;

	.cardUserContent{
		width: 25%;
	}
}
