@import "../../styles/colors";
@import "../../styles/flex";

.pageContainer {
  @include flex(column, flex-start, center);
  width: 100%;
  height: 100%;

  .pageContainerContent {
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    width: 100%;
    max-width: 100%;
    @media (max-width: 600px) {
      padding: 0;
      width: 100%;
    }
  }
}
