@import "../../../styles/flex";

.welcomeContainer {
	text-align: center;
	font-size: 2em;
	font-weight: 700;
	padding-top: 1em;
}

.cardStats{
	@include flex(row, center, center);
	padding: 20px;
}
